.approval-list-item {
    display: flex;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #e0e0e0;
    background-color: #fff;
    & > div{
      align-self: baseline;
    }
  
    &.pending {
      background-color: #fff9c4;
    }
  
    &.approved {
      background-color: #e8f5e9;
    }
  
    &.rejected {
      background-color: #ffebee;
    }
  }
  
  .status-icon {
    margin-right: 16px;
  }