.approval-tabs {
  margin-top: 10px;
  background-color: #F5F7F7;
  .MuiTabs-flexContainer {
    gap: 10px;
  };

  .approval-tab-item {
    border: 2px solid transparent;
    padding: 1px 4px;
    &.Mui-selected {
      border: 2px solid #96b3b6; // Customize the border color and width
      border-radius: 4px; // Optional: Add border radius
      background-color: #f5f7f7;
    }
  }

  .tab-content {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .tab-icon-count {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    gap: 5px;
  }
  @media screen and (min-width: 1280px) {
    &.MuiTabs-root {
      background-color: #fff;
    }
    
  }
}

.approval-tabs-dropdown {
   margin: 8px 0 8px 8px;
  .MuiInputBase-input {
    font-size: 1rem;
    padding: 8px 32px 8px 13px;
    
  }
  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
  }
}