.approval-details {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0 20px;
  height: 100%;
  overflow: auto;

  .approval-detail-back-btn{
    align-self: end;
    margin-right: -16px;
    margin-left: -8px;
    margin-top: -3px;
    color: #252525;
  }
 
  .approval-detail-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    background-color: #003f2d14;
    padding: 20px;
    margin: 0 -20px 6px;

    ul{
      margin: 0;
      padding: 0 0 0 10px;
      li > h6 {
        margin-left: -5px;
        color: #565656;
      }
      li::marker{
        color: #565656;
      }
    }

    @media screen and (max-width: 960px) {
      flex-direction: column;
      align-items: start;
      padding: 5px 10px 10px 14px;
      margin: 0 -20px 20px;
      
    }

    @media screen and (min-width: 960px) and (max-width: 1279px) {
      flex-direction: column;
      align-items: start;
      padding: 5px 0px 10px 14px;
      margin: 0 -20px 0;
      background: white;
      gap: 6px;
      
    }

  }

  .approval-preview-card-details{
    @media screen and (min-width: 1280px) {
      & > div{
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
        & .details-section{
          width: 100%;
          min-height: 1px;
          padding-right: 15px;
          padding-left: 15px;
          padding-bottom: 15px;
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
            
    }
  }
  .asset-id-text{
    color: #003F2D;
    text-decoration: underline;
    cursor: pointer;
  }
  .approval-details-bar{
    width: 100%;
    & .MuiTabs-scroller{
      height: 40px;
    }
  }
  .approval-details-container-tab-item{
    padding: 8px;
    min-height: 40px;
    font-size: 16px;
  }
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  hr{
    margin: 0 8px;
  }
  .MuiButton-root {
    &:hover {
      margin: 0 !important;
    }
    &:not(:last-child) {
      margin: 0 !important;
    }
  }

  @media screen and (max-width: 600px) {
    flex-direction: column-reverse;
    width: 100%;
    & .approval-action-buttons, button {
      width: 100%;
    }
  }

}

.details-section {
  & > h6{
    border-radius: 4px 4px 0px 0px;
    border-bottom: 1px solid rgba(0, 63, 45, 0.15);
    margin-bottom: 8px;
  }
}

.detail-item {
  margin-bottom: 10px;
}

.attachments-list {
  margin-top: 8px;
}

