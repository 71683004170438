
.approval-request-details-container{

.approvals-request-bar{
    & .MuiTabs-scroller{
      height: 40px;
    }
  
    .approval-request-container-tab-item{
      padding: 8px;
      min-height: 40px;
      font-size: 16px;
    }
  }

  .approval-request-header{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 6px;
    .approval-action-buttons{
      justify-content: unset;
      button{
        width: 100%;
      }
    }

    @media screen and (min-width: 600px) {
      .approval-action-buttons{
        button{
          width: auto;
        }
      }
    }

    @media screen and (min-width: 960px) {
      flex-direction: row;  
      h5 {
        margin-left: 3px;
      }      
    }

    @media screen and (min-width: 1280px) {
      flex-direction: row;    
      
    }
  }

  @media screen and (min-width: 960px) {
    padding-top: 8px !important;
    padding-right: 0px !important;
  } 
  .desktop-request-detail-container{
    .approval-request-detail-tab-panel{
      background-color:  #F5F7F7;
      padding: 15px;
      margin-inline: -15px;
      height: calc(100vh - 95px);
      overflow: auto;
      & > div{
        background-color: white;
        border-radius: 4px;
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
        overflow: auto;
      }

    }
    
  }
 
}